import "./flash-messages.scss";
import FlashMessage from "./FlashMessage";
import {useSelector} from "react-redux";

export const TYPE_INFO = 'info';
export const TYPE_ERROR = 'error';

export default function FlashMessages() {
  const messages = useSelector(state => state.flashMessages.messages);

  return (
    <div className={'flash-messages'}>
      {renderMessages()}
    </div>
  );

  function renderMessages() {
    if (0 === messages.length) {
      return;
    }

    return messages.map(message => {
      return (
        <FlashMessage
          key={message.key}
          index={message.key}
          type={message.type}
          title={message.title}
          message={message.message}
        />
      );
    });
  }
}
