import "./logo.scss";
import logo from "./logo.svg";
import {Link} from "react-router-dom";

export default function Logo() {
  return (
    <div className={'logo'}>
      <Link to={'/'}>
        <img src={logo} alt={'Всесибирская олимпиада'}/>
      </Link>
    </div>
  );
}
