import "./control-button.scss";
import {Link} from "react-router-dom";

export default function ControlButton(props) {
  const {link, icon, alt = ''} = props;

  return (
    <Link to={link} className="control-button">
      <img src={icon} alt={alt} className="control-button__icon" />
    </Link>
  );
}
