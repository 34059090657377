import "./sign-out.scss";
import signOutIcon from "./sign-out.png";
import {Button} from "react-bootstrap";
import {syncProfile} from '../../storage/redux/slices/user';
import {useDispatch} from "react-redux";
import {signOut} from "../../services/backend";
import useErrorHandler from "../../hooks/api/useErrorHandler";

export default function SignOutButton() {
  const dispatch = useDispatch();
  const errorHandler = useErrorHandler();

  return (
    <div className="sign-out">
      <Button onClick={handleClick} className="sign-out-button"><img src={signOutIcon} alt="Выйти" /></Button>
    </div>
  );

  async function handleClick() {
    try {
      await signOut();
      dispatch(syncProfile());
    } catch (error) {
      errorHandler(error);
    }
  }
}
