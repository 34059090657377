import "./header.scss";
import {Row} from "react-bootstrap";
import AuthUser from "../user/AuthUser";
import {useSelector} from "react-redux";

export default function HeaderActions() {
  const isGuest = useSelector((state) => state.user.isGuest);

  const cssClasses = [
    'header',
    'actions-header',
  ];

  if (!isGuest) {
    cssClasses.push('auth-header');
  }

  return (
    <div className={cssClasses.join(' ')}>
      <div className="content">
        <Row>
          <AuthUser/>
        </Row>
      </div>
    </div>
  );
}
