export const ROLE_ROOT = 'root';
export const ROLE_ADMIN = 'admin';
export const ROLE_MODERATOR = 'moderator';
export const ROLE_JURY = 'jury';
export const ROLE_MANAGER = 'manager';
export const ROLE_PARTICIPANT = 'participant';

const Role = {
  getLabels(isMultiple = false) {
    if (isMultiple) {
      return {
        [ROLE_ROOT]: 'Администраторы системы',
        [ROLE_ADMIN]: 'Администраторы олимпиады',
        [ROLE_MODERATOR]: 'Ответственные по предмету',
        [ROLE_JURY]: 'Жюри',
        [ROLE_MANAGER]: 'Координаторы площадок',
        [ROLE_PARTICIPANT]: 'Участники',
      };
    } else {
      return {
        [ROLE_ROOT]: 'Администратор системы',
        [ROLE_ADMIN]: 'Администратор олимпиады',
        [ROLE_MODERATOR]: 'Ответственный по предмету',
        [ROLE_JURY]: 'Жюри',
        [ROLE_MANAGER]: 'Координатор площадки',
        [ROLE_PARTICIPANT]: 'Участник',
      };
    }
  },
  getCodes() {
    return Object.keys(this.getLabels());
  },
  getLabel(roleName, options = {}) {
    const defaultOptions = {
      isMultiple: false,
    };
    
    options = {...defaultOptions, ...options};
    
    const labels = this.getLabels(options.isMultiple);
    
    return labels[roleName] || '';
  },
};

export default Role;

