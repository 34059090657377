import "./copyright.scss";

export default function Copyright() {
  return (
    <div className="copyright">
      © {getYears()} Специализированный учебно-научный центр НГУ
    </div>
  );

  function getYears() {
    return '2021 — ' + (new Date()).getFullYear();
  }
}
