import "./auth-user.scss";
import {Col} from "react-bootstrap";
import SignOutButton from "../sign-out/SignOutButton";
import {useSelector} from "react-redux";

export default function AuthUser(props) {
  const profile = useSelector((state) => state.user.profile);

  if (null === profile) {
    return null;
  }

  const name = [
    profile.firstname,
    profile.lastname,
  ];

  return (
    <>
      <Col xs="auto">
        <div className="avatar">
          <img src={profile.avatar} alt="Аватар" />
        </div>
      </Col>
      <Col className="user-info">
        <div className="user-name">{name.join(' ')}</div>
        <div className="user-role">{profile.role.description}</div>
      </Col>
      <Col xs="auto">
        <SignOutButton />
      </Col>
    </>
  );
}
