import "./block-header.scss";
import ControlsGroup from "../controls/ControlsGroup";

export default function BlockHeader(props) {
  const {title, editLink = null, deleteLink = null, variant = null} = props;

  return (
    <div className="flex-block block-header">
      <div className="flex-item title">{title}</div>
      <div className="flex-item action-buttons">
        <ControlsGroup editLink={editLink} deleteLink={deleteLink} variant={variant}/>
      </div>
    </div>
  );
}
