import "./assets/telegram-icon.scss";
import icon from "./assets/telegram.png";

export default function TelegramIcon() {
  return (
    <a className={'telegram-icon'} href={'https://t.me/vsesib_olymp'} target={'_blank'}>
      <img src={icon} alt={'tg'}/>
    </a>
  );
}
