import {Col} from "react-bootstrap";
import {BrowserRouter as Router} from "react-router-dom";
import HeaderContent from "../components/header/HeaderContent";
import HeaderActions from "../components/header/HeaderActions";
import Content from "../router/content/Content";
import Actions from "../router/action/Actions";
import FooterAuth from "../components/footer/FooterAuth";

export default function Auth() {
  return (
    <Router>
      <Col xs={8} className="content-side">
        <HeaderContent/>
        <Content/>
        <FooterAuth/>
      </Col>
      <Col xs={4} className="actions-side" id="actions-side">
        <HeaderActions/>
        <Actions/>
      </Col>
    </Router>
  );
}
