import "@fontsource/mulish";
import "@fontsource/pt-sans";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.scss";
import React from "react";
import ReactDOM from "react-dom";
import {Provider} from "react-redux";
import store from "./storage/redux/store";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import ReactHintFactory from "react-hint";

const ReactHint = ReactHintFactory(React);

ReactDOM.render(
  <React.StrictMode>
    <ReactHint autoPosition events delay={{show: 100, hide: 1000}}/>
    <Provider store={store}>
      <App/>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
