import {request} from "../request/request";

const participant = {
  findAll: (params) => request.read('/participant/find-all', params, true),
  findOne: (params) => request.read('/participant/find-one', params, true),
  getAll: (params) => request.read('/participant', params, true),
  getAttendanceReport: (params) => request.read('/participant/attendance-report', params, true),
  getCertificate: (participantId) => request.read(`/participant/${participantId}/certificate`, null, true),
  getCount: (params) => request.read('/participant/count', params, true),
  getDeliveryWorkReport: (params) => request.read('/participant/delivery-work-report', params, true),
  getEnrolParticipant: (params) => request.read('/participant/enrol', params, true),
  getLocation: (olympiadStepId) => request.read(`/participant/${olympiadStepId}/location`, null, true),
  getMy: () => request.read('/participant/my', null, true),
  /**
   * @deprecated Use Participant.findAll() instead of this method.
   * @param olympiadId
   * @returns {Promise<boolean|*|undefined>}
   */
  getMySummary: (olympiadId) => request.read(`/participant/${olympiadId}/my-summary`, null, true),
  getOne: (id) => request.read(`/participant/${id}`, null, true),
  getQuestionnaire: (olympiadStepId) => request.read(`/participant/${olympiadStepId}/questionnaire`, null, true),
  getQuestionnaireFile: (olympiadStepId) => request.read(`/participant/${olympiadStepId}/questionnaire-file`, null, true),
  getResultsReport: (params) => request.read('/participant/result-report', params, true),
  getResultReportFile: (params) => request.read('/participant/result-report-file', params, true),
  getTotalReport: (params) => request.read('/participant/total-report', params, true),
  getReportFile: (params) => request.read('/participant/report-file', params, true),
  getStudyClass: (olympiadId) => request.read(`/participant/${olympiadId}/class`, null, true),
  getScore: (olympiadStepId) => request.read(`/participant/${olympiadStepId}/score`, null, true),
  postAttendanceUpload: (data) => request.create('/participant/attendance-upload', data, true),
  postCheckedWorkUpload: (data) => request.create('/participant/checked-work-upload', data, true),
  postAdd: (data) => request.create('/participant/add', data, true),
  postOne: (data) => request.create('/participant', data, true),
  postPagesUpload: (data) => request.create('/participant/pages-upload', data, true),
  putAttendance: (participantId, data) => request.update(`/participant/${participantId}/attendance`, data, true),
  putPages: (participantId, data) => request.update(`/participant/${participantId}/page`, data, true),
  putQuestionnaire: (olympiadStepId, data) => request.update(`/participant/${olympiadStepId}/questionnaire`, data, true),
  putViewedQuestionnaire: (olympiadStepId) => request.update(`/participant/${olympiadStepId}/viewed-questionnaire`, null, true),
  remove: (id) => request.remove(`/participant/${id}`, null, true),
};

export {participant};
