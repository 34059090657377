import "./contacts.scss";
import TelegramIcon from "../socials/TelegramIcon";

export default function Contacts() {
  return (
    <div className={'contacts'}>
      <p className={'in-one-line'}>630090, г. Новосибирск, ул. Пирогова 4</p>
      <p>Телефон: (383) 363-41-63</p>
      <p>
        <span>E-mail: </span>
        <a href={'mailto:vsesib@nsu.ru'}>vsesib@nsu.ru</a>
      </p>
      <p>
        <span>Наши соц. сети: </span>
        <TelegramIcon/>
      </p>
    </div>
  );
}
